import React from 'react'
import { Link } from "gatsby"

const NavLink = ({ name, path, css, children }) => {
    const linkPrefix = "Link to: "

    if (path.includes("http")) {
        return <a href={path} className={css} title={linkPrefix + name} target="_blank" rel="noreferrer">{children || name}</a>
    } else {
        return <Link to={path} className={css} title={linkPrefix + name}>{children || name}</Link>
    }

}
export default NavLink