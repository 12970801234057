import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faLinkedin,
  faInstagram,
  faFacebook,
  faTwitter,
  faGoogle,
} from "@fortawesome/free-brands-svg-icons" // <i className="fas fa-bars"></i> diventa faBars faLinkedin

const SocialIcon = props => {
  let icon = null
  switch (props.social) {
    case "Linkedin":
      icon = <FontAwesomeIcon icon={faLinkedin} className={props.css} />
      break

    case "Instagram":
      icon = <FontAwesomeIcon icon={faInstagram} className={props.css} />
      break

    case "Facebook":
      icon = <FontAwesomeIcon icon={faFacebook} className={props.css} />
      break

    case "Twitter":
      icon = <FontAwesomeIcon icon={faTwitter} className={props.css} />
      break

    default:
      icon = <FontAwesomeIcon icon={faGoogle} className={props.css} />
  }

  return icon
}

export default SocialIcon
