import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import NavLink from "./navlink"
import SocialIcon from "./socialicon"

import { config } from "@fortawesome/fontawesome-svg-core"
config.autoAddCss = false // Disable the auto CSS insertion

const currentDate = new Date();
const currentYear = currentDate.getFullYear();

const Footer = props => {
  return (
    <footer className="text-gray-600 px-6 lg:px-8 pt-2">
      {getBaseFooter()}
    </footer>
  )
}

function getBaseFooter() {

  const socialSize = "text-md" // Dimensione icone social

  return (
    <div className="border-t-2 border-gray-200 max-w-screen-xl mx-auto flex flex-col md:flex-row justify-between items-center">

      <nav className="flex flex-wrap justify-center space-x-5">
        <NavLink name="The Team" path="/team" css="text-black text-sm hover:text-gray-400" />
        <NavLink name="Contact Us" path="/contact" css="text-black text-sm hover:text-gray-400" />
        <NavLink name="Partners" path="/partners" css="text-black text-sm hover:text-gray-400" />
      </nav>

      <nav className="flex flex-wrap justify-center space-x-2">

        <NavLink name="Linkedin" path="https://www.linkedin.com/company/theplatinumservicesdmcitalyandevents" css="text-black text-sm hover:text-gray-400" >
          <span className="flex-2">
            <SocialIcon social="Linkedin" css={socialSize + " text-blue-500"} />
          </span>
          <span className={socialSize + " flex-2 ml-2"}>Linkedin</span>
        </NavLink>

        <NavLink name="Instagram" path="https://www.instagram.com/platinumservices_events_italy/" css="text-black text-sm hover:text-gray-400" >
          <span className="flex-2">
            <SocialIcon social="Instagram" css={socialSize + " text-red-500"} />
          </span>
          <span className={socialSize + " flex-2 ml-2"}>Instagram</span>
        </NavLink>
      </nav>

      <nav className="flex flex-wrap justify-center space-x-3">
        <StaticImage src={"../images/global_24.jpg"} height={30} alt="Logo Micebook" className="align-middle" />
        <StaticImage src={"../images/connections.png"} height={30} alt="Logo Connections" className="align-middle" />
        <StaticImage src={"../images/evintra.png"} height={30} alt="Logo Evintra" className="align-middle" />
        <StaticImage src={"../images/mpi.png"} height={30} alt="Logo Mpi" className="align-middle" />
        <StaticImage src={"../images/micebook.jpg"} height={30} alt="Logo Evintra" className="align-middle" />
        <StaticImage src={"../images/cckas_ukas.jpg"} height={30} alt="Logo CCKAS UKAS" className="align-middle" />
      </nav>

      <p className="text-sm text-center md:text-right">
        ©{currentYear} The Platinum Services | All rights reserved
      </p>
    </div>
  )
}

export default Footer

/*



        <NavLink name="Evintra" path="https://www.evintra.com/">
          <StaticImage src={"../images/evintra.png"} height={30} alt="Logo Evintra" className="align-middle" />
        </NavLink>


        <NavLink name="Brochure" path="https://www.theplatinumservices.it/presentation.pdf" css="text-black text-sm hover:text-gray-400" />



function getFooter() {

  return (
    <div id="bigFooter" className="" onMouseOver={Entra} onFocus={Entra} onMouseOut={Esci} onBlur={Esci} aria-hidden="true">
      qui ci va il menu grande
    </div>
  )

}

function getBaseFooter() {

  let Entra = () => {
    let element = document.getElementById("bigFooter")
    element.classList.remove("hidden")
  }


  return (
    <div onMouseOver={Entra} onFocus={Entra} aria-hidden="true">
      questo è il footer base (quello piccolo e sempre in vista)
    </div>
  )

}







*/
