export const BackgroundImg = (dataSet, size = null) => {
  let obj_BG = {}
  let obj_Keys = []

  dataSet.split(",").forEach(element => {
    let tmpObj = element.replace("\n", "").split(" ")
    let sKey = tmpObj[1].replace("w", "")
    obj_BG[sKey] = tmpObj[0]
    obj_Keys.push(sKey)
  })

  let background_IMG = ""
  if (size) {
    switch (size) {
      case "big":
        background_IMG = obj_BG[obj_Keys[obj_Keys.length - 1]]
        break

      case "min":
        background_IMG = obj_BG[obj_Keys[0]]
        break

      case "small":
        background_IMG = obj_BG[obj_Keys[1]]
        break

      default:
        // default è medium
        let iPointer = 0
        let iTot = obj_Keys.length

        if (iTot === 2) { iPointer = 1 } 

        else if (iTot < 2) { iPointer = 0 } 
        
        else {
          if (iTot % 2 === 0) {
            // È pari ma si conta da 0
            // iPointer = iTot / 2 - 1 // Questo da la versione più piccola tra le immagini medie
            iPointer = iTot / 2 // Questo da la versione un po' più grande
          } else {
            iPointer = (iTot - 1) / 2
          }
        }

        background_IMG = obj_BG[obj_Keys[iPointer]]
    }
  } else {

    background_IMG = obj_BG[obj_Keys[obj_Keys.length - 1]] // imposto l'ultima immagine (la più grande) come base così mi cautelo da schermi giganteschi
    try {
      const iMaxSize = window.innerWidth > window.innerHeight ? window.innerWidth : window.innerHeight
      obj_Keys.reverse().forEach(objKey => {
        // scorro l'array al contrario (dall'immagine più grande in giù)
        if (iMaxSize < objKey) {
          background_IMG = obj_BG[objKey]
        } // Finché la dimensione di bg è superiore, sostituisco url con il nuovo
      })

    } catch {
      console.log("DefaultBG")
    }

  }

  return background_IMG
}
