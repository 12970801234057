import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { BackgroundImg } from "./functions/background_img"

import Header from "./header"
import Footer from "./footer"

const Layout = ({
  location,
  title,
  pageKeywords,
  description,
  isIndex = false,
  bgImg,
  children,
}) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
      allFile(filter: { relativeDirectory: { eq: "background" } }) {
        nodes {
          base
          childImageSharp {
            gatsbyImageData(
              width: 2000
              quality: 50
              webpOptions: { quality: 70 }
            )
          }
        }
      }
    }
  `)

  let bgDevelop = "sm:bg-green-200 md:bg-yellow-200 lg:bg-red-200 xl:bg-blue-200 2xl:bg-purple-200"
  bgDevelop = ""

  let bgStyle = {}

  // condition ? exprIfTrue : exprIfFalse
  if (bgImg) {
    const bgData = data.allFile.nodes.find(element => {
      // qui stabilisce la condizione per la restituzione di element (che contiene tutto l'oggetto immagine),
      // ovvero che il nome dell'immagine corrisponda a bgImg
      return element.base === bgImg
    })

    // backgroundRepeat: repeat|repeat-x|repeat-y|no-repeat|initial|inherit;
    // backgroundSize: auto|length|cover|contain|initial|inherit;
    bgStyle = {
      backgroundImage: 'url("' + BackgroundImg(bgData.childImageSharp.gatsbyImageData.images.fallback.srcSet) + '")',
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
    }
  }

  return (
    <div className={bgDevelop + " flex flex-col h-screen"} style={bgStyle} >
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />

      <main className="flex-1 overflow-y-auto px-0">
        <div className="lg:px-6 xl:px-20 xl:py-5">{children}</div>
      </main>

      <div className="bg-white text-center">
        <Footer />
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout


/*

   <div className="flex-1 max-w-sm rounded overflow-hidden shadow-lg m-2">
          <img
            className="w-full"
            src="https://i.picsum.photos/id/249/400/300.jpg"
            alt="Sunset in the mountains"
          />
          <div className="px-6 py-4">
            <div className="font-bold text-xl mb-2">The Coldest Sunset</div>
            <p className="text-gray-700 text-base">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit.
              Voluptatibus quia, nulla! Maiores et perferendis eaque,
              exercitationem praesentium nihil.
            </p>
          </div>
          <div className="px-6 py-4">
            <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2">
              #photography
            </span>
            <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2">
              #travel
            </span>
            <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700">
              #winter
            </span>
          </div>
        </div>
     


          <a href="#" className="text-gray-700 hover:text-gray-800 m-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              className="feather feather-globe"
            >
              <circle cx="12" cy="12" r="10"></circle>
              <line x1="2" y1="12" x2="22" y2="12"></line>
              <path d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"></path>
            </svg>
          </a>
          
          <a
            href="https://twitter.com/"
            className="text-gray-700 hover:text-gray-800 m-2  "
          >
            <svg style="width:24px;height:24px" viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M22.46,6C21.69,6.35 20.86,6.58 20,6.69C20.88,6.16 21.56,5.32 21.88,4.31C21.05,4.81 20.13,5.16 19.16,5.36C18.37,4.5 17.26,4 16,4C13.65,4 11.73,5.92 11.73,8.29C11.73,8.63 11.77,8.96 11.84,9.27C8.28,9.09 5.11,7.38 3,4.79C2.63,5.42 2.42,6.16 2.42,6.94C2.42,8.43 3.17,9.75 4.33,10.5C3.62,10.5 2.96,10.3 2.38,10C2.38,10 2.38,10 2.38,10.03C2.38,12.11 3.86,13.85 5.82,14.24C5.46,14.34 5.08,14.39 4.69,14.39C4.42,14.39 4.15,14.36 3.89,14.31C4.43,16 6,17.26 7.89,17.29C6.43,18.45 4.58,19.13 2.56,19.13C2.22,19.13 1.88,19.11 1.54,19.07C3.44,20.29 5.7,21 8.12,21C16,21 20.33,14.46 20.33,8.79C20.33,8.6 20.33,8.42 20.32,8.23C21.16,7.63 21.88,6.87 22.46,6Z"
              />
            </svg>
          </a>

          <a
            href="https://github.com/"
            className="text-gray-700 hover:text-gray-800 m-2"
          >
            <svg style="width:24px;height:24px" viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M12,2A10,10 0 0,0 2,12C2,16.42 4.87,20.17 8.84,21.5C9.34,21.58 9.5,21.27 9.5,21C9.5,20.77 9.5,20.14 9.5,19.31C6.73,19.91 6.14,17.97 6.14,17.97C5.68,16.81 5.03,16.5 5.03,16.5C4.12,15.88 5.1,15.9 5.1,15.9C6.1,15.97 6.63,16.93 6.63,16.93C7.5,18.45 8.97,18 9.54,17.76C9.63,17.11 9.89,16.67 10.17,16.42C7.95,16.17 5.62,15.31 5.62,11.5C5.62,10.39 6,9.5 6.65,8.79C6.55,8.54 6.2,7.5 6.75,6.15C6.75,6.15 7.59,5.88 9.5,7.17C10.29,6.95 11.15,6.84 12,6.84C12.85,6.84 13.71,6.95 14.5,7.17C16.41,5.88 17.25,6.15 17.25,6.15C17.8,7.5 17.45,8.54 17.35,8.79C18,9.5 18.38,10.39 18.38,11.5C18.38,15.32 16.04,16.16 13.81,16.41C14.17,16.72 14.5,17.33 14.5,18.26C14.5,19.6 14.5,20.68 14.5,21C14.5,21.27 14.66,21.59 15.17,21.5C19.14,20.16 22,16.42 22,12A10,10 0 0,0 12,2Z"
              />
            </svg>
          </a>
        


    








          

sticky footer: soluzione 1
    <div className="flex flex-col h-screen justify-between">
      <header className="h-10 bg-red-500">Header</header>
      <main className="mb-auto h-10 bg-green-500">Content</main>
      <footer className="h-10 bg-blue-500">Footer</footer>
    </div>

sticky footer: soluzione 2
<div className="flex flex-col h-screen">
    <div className="bg-red-500">header</div>
    <div className="bg-green-500 flex-grow">content</div>
    <div className="bg-blue-500">footer</div>
</div>

sticky footer: soluzione 3
    <>
      <nav className="bg-gray-400 flex items-center justify-between flex-wrap bg-teal-500 p-6">
        navbar
      </nav>

      <div className="flex justify-between">
        centro
      </div>
    
      <footer className="fixed h-10 bg-gray-500 w-full bottom-0 flex items-center justify-center">
        <div className="flex justify-center w-100">
          footer
        </div>
      </footer>
    </>

*/
