/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

// social {
//   twitter
//   facebook
//   linkedin
//   instagram
// }
function Seo({ lang, meta, title, kwords, path, image, description }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            keywords
            description
            siteUrl
            author {
              name
              summary
            }
            social {
              twitter
            }
          }
        }
      }
    `
  )

  const metaDescription = site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title
  const siteKeywords = site.siteMetadata.keywords.toLowerCase().split(",")
  const siteUrl = site.siteMetadata.siteUrl
  const pageKeywords = kwords || []
  const allKeywords = pageKeywords.concat( siteKeywords )
  const keywords = [...new Set( allKeywords )].join(",")
  
  
// og:image: l'URL dell'immagine che rappresenta il tuo contenuto.
// og:url: l'URL unico del tuo contenuto che sarà usato come suo ID permanente.
// og:description: ladescrizione che riassume la tua immagine. la didascalia

  return (
    <Helmet
      htmlAttributes={{ lang, }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}

      meta={[
        {
          name: `keywords`,
          content: keywords,
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:url`,
          content: siteUrl + path,
        },
        {
          property: `og:image`,
          content: siteUrl + image,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author.name || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default Seo
